import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { NFTRealityBanner } from 'src/modules/nft-reality-banner';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 500px;
    padding-top: 12.5rem;
    padding-left: 0;
    padding-right: 0;
`;

export const NFTRealitySection = () => {
    const { formatMessage } = useIntl();

    return (
        <SContainer id="nft-reality">
            <NFTRealityBanner
                h1Part1={formatMessage({
                    id: 'nft-reality-banner.h1Part1',
                })}
                h1Part2={formatMessage({
                    id: 'nft-reality-banner.h1Part2',
                })}
                description={formatMessage({
                    id: 'nft-reality-banner.description',
                })}
            />
        </SContainer>
    );
};
