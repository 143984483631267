import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { CaseStudyTags } from 'views/case-studies/components/case-study/case-study-tags';
import { ArrowLink } from 'components/arrow-link';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const SWrapper = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: center;
    ${CONSTANTS.MEDIA.max600`
        align-items: center;
        flex-direction: column;
    `}
`;

const SParagraph = styled(Paragraph)`
    margin-bottom: 1.5rem;
    height: 10rem;

    ${CONSTANTS.MEDIA.max1024`
       height: auto;
    `}
`;

const SHeaderThird = styled(HeaderThird)`
    margin-bottom: 0.75rem;
`;

const SImageWrapper = styled.div`
    flex: 0.58;
`;

const SImageBox = styled.div`
    width: fit-content;
    height: 20rem;
    margin-bottom: 3rem;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 1.5rem 2.25rem -0.625rem rgba(0, 0, 0, 0.1);
    &:hover {
        box-shadow: 0 1.5rem 2.25rem -0.625rem rgba(0, 0, 0, 0.3);
    }
`;

const STextWrapper = styled.div`
    flex: 0.42;
    margin-top: 3.5rem;

    ${CONSTANTS.MEDIA.max1024`
        flex: 1;
    `}
`;

const STagsContainer = styled.div`
    height: 3.3rem;
    white-space: nowrap;

    ${CONSTANTS.MEDIA.max1024`
       height: auto;
    `}
`;

const SArrowLink = styled(ArrowLink)`
    margin-top: 0.4rem;
`;

const SPrefixLangLink = styled(PrefixLangLink)`
    height: 100%;
`;

export const Project = ({ title, desc, img, tags, alt, link }) => {
    const { formatMessage } = useIntl();

    return (
        <SWrapper>
            <SImageWrapper>
                <SImageBox>
                    <SPrefixLangLink
                        to={link}
                        aria-label={formatMessage({ id: title })}
                    >
                        <GatsbyImage
                            image={img.gatsbyImageData}
                            style={{ height: '100%' }}
                            alt={formatMessage({ id: alt })}
                        />
                    </SPrefixLangLink>
                </SImageBox>
            </SImageWrapper>
            <STextWrapper>
                <SHeaderThird>
                    <FormattedMessage id={title} />
                </SHeaderThird>
                <SParagraph>
                    <FormattedMessage id={desc} />
                </SParagraph>
                <STagsContainer>
                    <CaseStudyTags tags={tags} />
                </STagsContainer>
                <SArrowLink
                    to={link}
                    ariaLabel={formatMessage({ id: title })}
                />
            </STextWrapper>
        </SWrapper>
    );
};

Project.propTypes = {
    img: object.isRequired,
    alt: string.isRequired,
    desc: string.isRequired,
    title: string.isRequired,
    link: string.isRequired,
};
