import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import { slidingAnimation } from 'styles/utilities/index';
import DevelopersImage from 'svgs/main-page/developers.svg';

const SDevelopersAnimation = styled.div`
    #speech-bubble {
        ${slidingAnimation(0, 10)};
    }
    #triangle-1 {
        ${slidingAnimation(-10, 10)};
    }
    #triangle-2 {
        ${slidingAnimation(10, 10)};
    }
    #triangle-3 {
        ${slidingAnimation(10, 10)};
    }
    #dot {
        ${slidingAnimation(10, -10)};
    }
    #message {
        ${slidingAnimation(0, -10)};
    }
`;

export const Developers = ({ title }) => {
    return (
        <SDevelopersAnimation>
            <DevelopersImage height="100%" width="100%" title={title} />
        </SDevelopersAnimation>
    );
};

Developers.prototype = {
    title: string,
};
