import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { array, func, number } from 'prop-types';

const SWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${CONSTANTS.MEDIA.max768`
        display: none;
    `}
`;

const SButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
`;

const SDiv = styled.div`
    &:before {
        width: calc(100% / ${({ length }) => length} - 0.75rem);
        margin-bottom: -0.188rem;
        content: '';
        background: var(--black-text-color);
        height: 0.188rem;
        display: block;
        transform: ${({ currentIndex }) =>
            css`translateX(calc(${currentIndex} * (100% + 1rem)))`};
        transition: transform 0.2s linear;
        border-radius: 0.5rem;
    }
`;

const SButton = styled.button`
    font-weight: 600;
    font-size: 0.813rem;
    margin: -0.25rem;
    padding: 0.25rem;
    color: ${({ isActive }) =>
        isActive ? 'var(--black-text-color)' : 'var(--gray-color-80)'};
    transition: color 0.2s linear;
    width: 100%;

    &:before {
        width: 100%;
        content: '';
        background: var(--gray-color);
        height: 0.188rem;
        display: block;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
    }
`;

export const CarouselNamedButtons = ({ selectedIndex, slides, scrollTo }) => {
    return (
        <SWrapper>
            <SDiv currentIndex={selectedIndex} length={slides.length} />
            <SButtons>
                {slides.map(({ title }, index) => {
                    return (
                        <SButton
                            key={index}
                            aria-controls="carousel-items-button"
                            length={slides.length}
                            isActive={selectedIndex === index}
                            index={index}
                            onClick={() => scrollTo(index)}
                        >
                            <FormattedMessage id={title} />
                        </SButton>
                    );
                })}
            </SButtons>
        </SWrapper>
    );
};

CarouselNamedButtons.propTypes = {
    selectedIndex: number.isRequired,
    slides: array.isRequired,
    scrollTo: func.isRequired,
};
