import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Buttons } from 'views/index/case-studies-section/components/buttons';
import { Carousel } from 'views/index/case-studies-section/components/carousel';
import { useCaseStudies } from 'views/index/case-studies-section/data';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import useEmblaCarousel from 'embla-carousel-react';
import { useTranslatedTitle } from 'hooks/use-translated-title';

const SContainer = styled(Container)`
    display: flex;
    align-items: center;
    gap: 3.25rem;
    content-visibility: auto;
    contain-intrinsic-size: 1000px;

    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
        gap: 2.25rem;
    `}
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: right;

    ${CONSTANTS.MEDIA.max768`
        text-align: center;
    `}
`;

const SColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;

    ${CONSTANTS.MEDIA.max768`
        align-items: center;
        gap: 1rem;
    `}
`;

const SColumnRight = styled.div`
    flex: 2;
`;

export const CaseStudiesSection = () => {
    const caseStudies = useCaseStudies();

    const [emblaRef, embla] = useEmblaCarousel({
        loop: true,
        draggable: false,
    });
    const [selectedIndex, setSelectedIndex] = useState(0);

    const scrollTo = useCallback(
        (index) => embla && embla.scrollTo(index),
        [embla],
    );
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) {
            return;
        }
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) {
            return;
        }
        onSelect();
        embla.on('select', onSelect);

        return () => {
            embla.off('select', onSelect);
        };
    }, [embla, onSelect]);

    const title = 'main-page.ourCaseStudySection.header';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id="case-studies">
            <SColumn>
                <SHeaderSecond
                    topParagraph="main-page.ourCaseStudySection.caseStudy"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <Buttons scrollPrev={scrollPrev} scrollNext={scrollNext} />
            </SColumn>
            <SColumnRight>
                <Carousel
                    ref={emblaRef}
                    selectedIndex={selectedIndex}
                    slides={caseStudies}
                    scrollTo={scrollTo}
                />
            </SColumnRight>
        </SContainer>
    );
};
