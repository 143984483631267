import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { func, node, string } from 'prop-types';

import { ExtendedContent } from './components/extended-content';
import { MainContent } from './components/main-content';
import { ShortContent } from './components/short-content';

const SContainer = styled(Container)`
    text-align: center;
    padding-top: ${({ shortVariant }) =>
        shortVariant ? CONSTANTS.MARGIN.PAGE_BIG : CONSTANTS.MARGIN.PAGE};
    margin-bottom: 0;
    overflow: hidden;
`;

export const CareersSection = ({
    children,
    containerClassName,
    shortContentCallback,
    imageAltsIds,
}) => {
    const shortVariant = !!shortContentCallback;

    return (
        <SContainer className={containerClassName} id="careers">
            <MainContent />
            {shortVariant ? (
                <ShortContent shortContentCallback={shortContentCallback} />
            ) : (
                <ExtendedContent imageAltsIds={imageAltsIds} />
            )}
            {children}
        </SContainer>
    );
};

CareersSection.propTypes = {
    children: node,
    shortContentCallback: func,
    imageAltsIds: {
        teamMemberRed: string,
        strongman: string,
        bookLover: string,
        teamMemberBlack: string,
    },
};
