import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 9.375rem;
    gap: 1.5rem;
    flex-wrap: wrap;

    ${CONSTANTS.MEDIA.max768`
        margin-bottom: 6rem;
    `}
`;

export const ShortContent = ({ shortContentCallback }) => {
    return (
        <SContainer>
            <Button variant="additional" onClick={shortContentCallback}>
                <FormattedMessage id="careers-section.seeJobOffers" />
            </Button>
            <Button
                variant="white"
                to={EXTERNAL_REDIRECTS.CULTURE_BOOK_URL}
                component="a"
                target="_blank"
                googleAnalytics={gaTrackedEvents.MAIN_PAGE.CONTENT.CULTURE_BOOK}
            >
                <FormattedMessage id="careers-section.downloadCultureBook" />
            </Button>
        </SContainer>
    );
};
