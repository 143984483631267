import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

// TODO: other projects
export const useCaseStudies = () => {
    const images = useStaticQuery(graphql`
        {
            m2m: file(
                relativePath: { eq: "case-studies/m2m-desktop-and-mobile.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 719
                        formats: [WEBP]
                    )
                }
            }
            cryptoExchange: file(
                relativePath: {
                    eq: "case-studies/cryptocurrency-exchange-platform.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 719
                        formats: [WEBP]
                    )
                }
            }
            cbreZone: file(relativePath: { eq: "case-studies/cbre-zone.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 719
                        formats: [WEBP]
                    )
                }
            }
            nftReality: file(
                relativePath: { eq: "case-studies/nft-reality-slack.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 719
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        {
            title: 'main-page.ourCaseStudySection.projects.m2m',
            image: images.m2m.childImageSharp.gatsbyImageData,
            path: `${PATHS.CASE_STUDIES}`,
        },
        {
            title: 'main-page.ourCaseStudySection.projects.nftReality',
            image: images.nftReality.childImageSharp.gatsbyImageData,
            path: `${PATHS.CASE_STUDIES}/${PATHS.NFT_REALITY}`,
        },
        {
            title: 'main-page.ourCaseStudySection.projects.crypto-exchange',
            image: images.cryptoExchange.childImageSharp.gatsbyImageData,
            path: `${PATHS.CASE_STUDIES}/${PATHS.CRYPTOCURRENCY_EXCHANGE_SOFTWARE}`,
        },
        {
            title: 'main-page.ourCaseStudySection.projects.cbreZone',
            image: images.cbreZone.childImageSharp.gatsbyImageData,
            path: `${PATHS.CASE_STUDIES}/${PATHS.COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE}`,
        },
    ];
};
