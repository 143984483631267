import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ServiceTab } from 'views/index/services-section/components/service-tab';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { arrayOf, shape, string } from 'prop-types';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 500px;
`;

const STextWrapper = styled.div`
    margin-bottom: 3.75rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
`;

const SParagraph = styled(Paragraph)`
    text-align: center;
    margin: 1.5rem auto 0;
    max-width: 50rem;
`;

const SWrapper = styled.div`
    display: flex;
    gap: 2.25rem;
    flex-direction: column;
    ${CONSTANTS.MEDIA.max1024`
        gap: 1.25rem;
    `}
`;

export const TabSection = ({ tabs, topParagraph, title, description, id }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].id);

    const tabsToRender = tabs.map((tab, index) => (
        <ServiceTab
            key={tab.id}
            index={index}
            tab={tab}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        />
    ));

    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id={id}>
            <STextWrapper>
                <SHeaderSecond
                    topParagraph={topParagraph}
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                {description && (
                    <SParagraph>
                        <FormattedMessage id={description} />
                    </SParagraph>
                )}
            </STextWrapper>
            <SWrapper>{tabsToRender}</SWrapper>
        </SContainer>
    );
};

TabSection.propTypes = {
    title: string.isRequired,
    topParagraph: string,
    description: string,
    id: string,
    tabs: arrayOf(
        shape({
            id: string.isRequired,
            title: string.isRequired,
            description: string.isRequired,
            to: string,
        }),
    ),
};

TabSection.defaultProps = {
    topParagraph: '',
    description: '',
    id: '',
    tabs: [
        {
            id: '',
            title: '',
            description: '',
            to: '',
        },
    ],
};
