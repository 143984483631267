import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { Accordion } from 'modules/faq-accordion-section/components/accordion';

const SWrapper = styled.div`
    background-color: var(--gray-color-90);
`;

const SContainer = styled(Container)`
    padding-top: 10rem;
    padding-bottom: 10rem;
`;

const SHeader = styled.div`
    text-align: center;
    margin-bottom: 4rem;
`;

const SGrid = styled.div`
    display: grid;
    grid-template-columns: 3fr 4fr;

    ${CONSTANTS.MEDIA.max768`
       grid-template-columns: 1fr;
    `}
`;

const SFlex = styled.div`
    display: flex;
    gap: 2rem;
    margin-left: 3rem;

    ${CONSTANTS.MEDIA.max768`
       margin-left: 0rem;
    `}
`;

const SImageWrapper = styled.div`
    padding: 2rem;
`;
export const ExpertisesAccordionSection = ({ data }) => {
    const [active, setActive] = useState(0);

    const images = useStaticQuery(graphql`
        {
            fintech: file(relativePath: { eq: "fintech/fintech-mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 680
                        formats: [PNG]
                    )
                }
            }
            proptech: file(
                relativePath: {
                    eq: "real-estate-prop-tech-experts/real-estate-map.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 680
                        formats: [PNG]
                    )
                }
            }
            media: file(
                relativePath: { eq: "audio-video/streaming-platform.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 680
                        formats: [PNG]
                    )
                }
            }
            blockchain: file(
                relativePath: {
                    eq: "single-case-study/music-distribution/collectible.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 680
                        formats: [PNG]
                    )
                }
            }
        }
    `);

    const activeExpertiseName = data[active || 0].name;

    const title = 'main-page.expertisesSection.weSpecializeIn';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SWrapper>
            <SContainer>
                <SHeader>
                    <HeaderSecond
                        topParagraph="main-page.expertisesSection.expertises"
                        id={kebabCaseId}
                        handleCopyUrl={handleCopyUrl}
                    >
                        <FormattedMessage id={title} />
                    </HeaderSecond>
                </SHeader>

                <SGrid>
                    <SImageWrapper>
                        <GatsbyImage
                            imgStyle={{
                                objectFit: 'cover',
                                objectPosition: 'right',
                                width: '100%',
                            }}
                            alt={activeExpertiseName}
                            image={
                                images[activeExpertiseName].childImageSharp
                                    .gatsbyImageData
                            }
                        />
                    </SImageWrapper>
                    <SFlex>
                        <Accordion
                            data={data}
                            boldQuestion={false}
                            setActiveItemIndex={setActive}
                            withTranslations
                            reverse
                            hideSeparators
                            activeBar
                        />
                    </SFlex>
                </SGrid>
            </SContainer>
        </SWrapper>
    );
};
