import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { motion, useInView } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MotionWrapper } from 'modules/careers-section/components/extended-content/motion-wrapper';
import { string } from 'prop-types';

const SButtons = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 38%;
    flex-wrap: wrap;
    ${CONSTANTS.MEDIA.max768`
        margin-bottom: 6rem;
    `}
`;

const SWrapper = styled.div`
    position: relative;
    display: flex;
    ${CONSTANTS.MEDIA.max768`
         height: 24rem;
    `}
`;

const STeamMemberRed = styled.div`
    width: 31%;
    position: absolute;
    bottom: 0;
    left: -2%;
    z-index: 0;
    ${CONSTANTS.MEDIA.max768`
        width: 369px;
        left: 50%;
        transform: translateX(-50%) translateX(-10rem);
    `}
`;

const SStrongMan = styled.div`
    width: 37%;
    position: absolute;
    bottom: 0;
    left: 19%;
    z-index: 1;
    ${CONSTANTS.MEDIA.max768`
        width: 438px;
        left: 50%;
        transform: translateX(-50%) translateX(8rem);
    `}
`;

const SBookLover = styled.div`
    width: 31%;
    position: absolute;
    bottom: 0;
    right: 23%;
    z-index: 1;
    ${CONSTANTS.MEDIA.max768`
        display: none;
    `}
`;

const STeamMemberBlack = styled.div`
    width: 35%;
    position: absolute;
    bottom: 0;
    right: -2%;
    z-index: 0;
    ${CONSTANTS.MEDIA.max768`
        display: none;
    `}
`;

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.3,
        },
    },
};

export const ExtendedContent = ({ imageAltsIds }) => {
    const ref = useRef(null);
    const isInView = useInView(ref);
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            teamMemberRed: file(
                relativePath: { eq: "team/team-member-red.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 369
                        formats: [WEBP]
                    )
                }
            }
            strongman: file(relativePath: { eq: "team/strongman.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 438
                        formats: [WEBP]
                    )
                }
            }
            bookLover: file(relativePath: { eq: "team/book-lover.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 365
                        formats: [WEBP]
                    )
                }
            }
            teamMemberBlack: file(
                relativePath: { eq: "team/team-member-black.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 417
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return (
        <>
            <SButtons ref={ref}>
                <Button
                    variant="additional"
                    to={PATHS.CAREERS}
                    component="gatsby-link"
                    googleAnalytics={gaTrackedEvents.MAIN_PAGE.CTA.CAREERS}
                >
                    <FormattedMessage id="careers-section.joinUs" />
                </Button>
                <Button
                    variant="white"
                    to={EXTERNAL_REDIRECTS.CULTURE_BOOK_URL}
                    component="a"
                    target="_blank"
                    googleAnalytics={
                        gaTrackedEvents.MAIN_PAGE.CONTENT.CULTURE_BOOK
                    }
                >
                    <FormattedMessage id="careers-section.downloadCultureBook" />
                </Button>
            </SButtons>
            <SWrapper>
                <motion.div
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={containerVariants}
                >
                    <STeamMemberRed>
                        <MotionWrapper>
                            <GatsbyImage
                                alt={formatMessage({
                                    id: imageAltsIds.teamMemberRed,
                                })}
                                image={
                                    images.teamMemberRed.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </MotionWrapper>
                    </STeamMemberRed>
                    <SStrongMan>
                        <MotionWrapper>
                            <GatsbyImage
                                alt={formatMessage({
                                    id: imageAltsIds.strongman,
                                })}
                                image={
                                    images.strongman.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </MotionWrapper>
                    </SStrongMan>
                    <SBookLover>
                        <MotionWrapper>
                            <GatsbyImage
                                alt={formatMessage({
                                    id: imageAltsIds.bookLover,
                                })}
                                image={
                                    images.bookLover.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </MotionWrapper>
                    </SBookLover>
                    <STeamMemberBlack>
                        <MotionWrapper>
                            <GatsbyImage
                                alt={formatMessage({
                                    id: imageAltsIds.teamMemberBlack,
                                })}
                                image={
                                    images.teamMemberBlack.childImageSharp
                                        .gatsbyImageData
                                }
                            />
                        </MotionWrapper>
                    </STeamMemberBlack>
                </motion.div>
            </SWrapper>
        </>
    );
};

ExtendedContent.prototype = {
    imageAltsIds: {
        teamMemberRed: string,
        strongman: string,
        bookLover: string,
        teamMemberBlack: string,
    },
};

ExtendedContent.defaultProps = {
    imageAltsIds: {
        teamMemberRed: 'careers-section.images.teamMemberInRed',
        strongman: 'careers-section.images.teamMemberInBlack',
        bookLover: 'careers-section.images.teamMemberWithBook',
        teamMemberBlack: 'careers-section.images.teamMemberWithLoupe',
    },
};
