import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { array } from 'prop-types';

const SWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const STooltipText = styled.p`
    visibility: hidden;
    color: var(--white-color);
    background-color: var(--black-text-color);
    font-size: 0.75rem;
    text-align: center;
    padding: 0.125rem 0.25rem;
    min-width: 10rem;
    border-radius: 0.5rem;
    position: absolute;
    top: -3rem;
    left: 50%;
    transform: translateX(-50%);

    span {
        font-weight: bold;
    }
`;

const STooltip = styled.div`
    position: relative;

    &:hover {
        ${STooltipText} {
            visibility: visible;
        }
    }
`;

const SMiniature = styled(GatsbyImage)`
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin-right: 0.5rem;
`;

export const AuthorsWithTooltip = ({ authors }) => {
    return (
        <SWrapper>
            {authors?.length > 0 &&
                authors.map(({ picture, name, jobTitle }, index) => {
                    const authorImage = getImage(picture);
                    return (
                        <STooltip key={index}>
                            <SMiniature image={authorImage} alt={name} />
                            <STooltipText>
                                <span>{name}</span> - {jobTitle}
                            </STooltipText>
                        </STooltip>
                    );
                })}
        </SWrapper>
    );
};

AuthorsWithTooltip.propTypes = {
    authors: array,
};

AuthorsWithTooltip.defaultProps = {
    authors: [],
};
