import React from 'react';
import styled from 'styled-components';
import { SocialMedia } from 'components/social-media';
import { CONSTANTS } from 'constants/styles/constants';

const SSocialMediaBanner = styled.div`
    position: absolute;
    top: 0.688rem;
    left: -4.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    ${CONSTANTS.MEDIA.max1200`
        display: none;
    `}
`;

export const SocialMediaBanner = () => {
    return (
        <SSocialMediaBanner>
            <SocialMedia />
        </SSocialMediaBanner>
    );
};
