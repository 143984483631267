import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { EXTERNAL_REDIRECTS } from 'constants/external-redirects';
import { PATHS } from 'constants/paths';
import { arrayOf, bool, node, object, string } from 'prop-types';

import { MainSectionBase } from './components/base';
import { CONSTANTS } from 'constants/styles/constants';

const SButtonsWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;

    ${CONSTANTS.MEDIA.max1024`
        align-items: center;
        justify-content: center;
        padding: 0 2.5rem;
        gap: 1rem;
    `}
`;

const SButton = styled(Button)`
    ${CONSTANTS.MEDIA.max1024`
        width: 100%
    `}
`;

export const MainSection = ({
    estimateProjectAnalytics,
    introCallAnalytics,
    ...baseProps
}) => {
    return (
        <MainSectionBase
            {...baseProps}
            buttonsSection={
                <SButtonsWrapper>
                    <SButton
                        to={PATHS.ESTIMATE_THE_PROJECT}
                        component="gatsby-link"
                        googleAnalytics={estimateProjectAnalytics}
                    >
                        <FormattedMessage id="general.estimateProject" />
                    </SButton>
                    <SButton
                        to={EXTERNAL_REDIRECTS.HUB_SPOT_MEETING}
                        variant="outline"
                        component="a"
                        googleAnalytics={introCallAnalytics}
                    >
                        <FormattedMessage id="general.introCall" />
                    </SButton>
                </SButtonsWrapper>
            }
        />
    );
};

MainSection.propTypes = {
    icon: node.isRequired,
    titleId: string.isRequired,
    subtitleId: string,
    topSubTitleId: string,
    withSocialMedia: bool,
    isMainPage: bool,
    iconWrapperCss: arrayOf(string).isRequired,
    textWrapperCss: arrayOf(string),
    paragraphs: arrayOf(string),
    estimateProjectAnalytics: object,
    introCallAnalytics: object,
    isDark: bool,
    isParagraphFormattedPreviously: bool,
    isSubtitleFormattedPreviously: bool,
};

MainSection.defaultProps = {
    withSocialMedia: false,
    isMainPage: false,
    subtitleId: '',
    topSubTitleId: '',
    paragraphs: [],
    estimateProjectAnalytics: undefined,
    introCallAnalytics: undefined,
    isDark: false,
    textWrapperCss: [''],
    isParagraphFormattedPreviously: false,
    isSubtitleFormattedPreviously: false,
};
