import { useEffect, useState } from 'react';

import { generateNpmApiUrl, GITHUB_REPOS_URL } from '../constants/api-url';

const MR_LISTED_OPEN_SOURCE_REPOS = {
    REACT_NATIVE_SELECT_PRO: 'react-native-select-pro',
    ESLINT_CONFIG: 'eslint-config',
    FLOW: 'flow',
};

const REPOS_SET = new Set([
    MR_LISTED_OPEN_SOURCE_REPOS.REACT_NATIVE_SELECT_PRO,
    MR_LISTED_OPEN_SOURCE_REPOS.ESLINT_CONFIG,
    MR_LISTED_OPEN_SOURCE_REPOS.FLOW,
]);

const UNIQUE_PACKAGES = {
    flow: 'flow-transport-real-grpc',
};

const todayDate = new Date().toJSON().slice(0, 10);

const resolvePackageName = (repoName) => {
    switch (repoName) {
        case MR_LISTED_OPEN_SOURCE_REPOS.FLOW:
            return UNIQUE_PACKAGES[repoName];
        default:
            return repoName;
    }
};

export const useFetchOpenSources = (openSourcesToFetch) => {
    const [openSourcesReposData, setOpenSourcesReposData] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const githubReposResponse = await fetch(GITHUB_REPOS_URL);
                const githubRepos = await githubReposResponse.json();
                const filteredRepos = githubRepos.filter(({ name }) =>
                    REPOS_SET.has(name),
                );

                const npmDownloadsResponse = await Promise.all(
                    filteredRepos.map((repo) =>
                        fetch(
                            generateNpmApiUrl({
                                endDate: todayDate,
                                packageName: resolvePackageName(repo.name),
                            }),
                        ),
                    ),
                );

                const npmDownloads = await Promise.all(
                    npmDownloadsResponse.map((item) => item.json()),
                );

                for (const [index] of filteredRepos.entries()) {
                    filteredRepos[index] = {
                        ...filteredRepos[index],
                        totalDownloads: npmDownloads[index].downloads,
                    };
                }

                setOpenSourcesReposData(filteredRepos);
            } catch {
                setOpenSourcesReposData([]);
            }
        })();
    }, [openSourcesToFetch]);

    return openSourcesReposData;
};
