import React from 'react';
import { SERVICES_TABS } from 'views/index/services-section/constants';
import { TabSection } from 'modules/tab-section';

export const ServicesSection = () => {
    return (
        <TabSection
            id="services"
            tabs={SERVICES_TABS}
            topParagraph="main-page.services-section.services"
            title="main-page.services-section.digitalProductDevelopment"
        />
    );
};
