import { CONSTANTS } from 'constants/styles/constants';

export const variantMediaQuery = (styles) => (props) => {
    const variantKey = props.variant || 'default';

    const variantQueryMap = {
        default: CONSTANTS.MEDIA.max768,
        blog: CONSTANTS.MEDIA.max1200,
    };

    return variantQueryMap[variantKey](styles);
};
