import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { CarouselNamedButtons } from 'views/index/case-studies-section/components/carousel-named-buttons';
import { CarouselSlides } from 'views/index/case-studies-section/components/carousel-slides';
import { array, func, number } from 'prop-types';

const SContainer = styled.div`
    margin-bottom: 1rem;
    overflow: hidden;
`;

const SWrapper = styled.div`
    display: flex;
`;

export const Carousel = forwardRef(
    ({ selectedIndex, slides, scrollTo }, ref) => {
        return (
            <>
                <SContainer ref={ref}>
                    <SWrapper id="carousel-items-button" aria-live="polite">
                        <CarouselSlides slides={slides} />
                    </SWrapper>
                </SContainer>
                <CarouselNamedButtons
                    selectedIndex={selectedIndex}
                    slides={slides}
                    scrollTo={scrollTo}
                />
            </>
        );
    },
);

Carousel.propTypes = {
    selectedIndex: number.isRequired,
    slides: array.isRequired,
    scrollTo: func.isRequired,
};

Carousel.displayName = 'Carousel';
