import { PATHS } from 'constants/paths';

export const EXPERTISES_DATA = [
    {
        name: 'fintech',
        question: 'main-page.expertisesSection.fintechBanking',
        answer: 'main-page.expertisesSection.weWillProvideCustomers',
        link: {
            to: `${PATHS.EXPERTISES}/${PATHS.FINTECH}`,
            label: 'general.readMore',
        },
    },
    {
        name: 'proptech',
        question: 'main-page.expertisesSection.realEstatePropTech',
        answer: 'main-page.expertisesSection.weWillCreateCustomized',
        link: {
            to: `${PATHS.EXPERTISES}/${PATHS.PROP_TECH_REAL_ESTATE}`,
            label: 'general.readMore',
        },
    },
    {
        name: 'media',
        question: 'main-page.expertisesSection.audioVideoProcessing',
        answer: 'main-page.expertisesSection.weWillDevelop',
        link: {
            to: `${PATHS.EXPERTISES}/${PATHS.AUDIO_VIDEO}`,
            label: 'general.readMore',
        },
    },
    {
        name: 'blockchain',
        question: 'main-page.expertisesSection.nftBlockchain',
        answer: 'main-page.expertisesSection.weWillImplement',
        links: [
            {
                key: 'link',
                url: 'https://flow.com/upgrade/crescendo/cadence-1',
                component: 'a',
            },
        ],
        link: {
            to: `${PATHS.EXPERTISES}/${PATHS.NFT}`,
            label: 'general.readMore',
        },
    },
];
