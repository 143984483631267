import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderFirst } from 'components/header-first';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';

const SHeaderOne = styled(HeaderFirst)`
    margin-bottom: 3.75rem;
    color: var(--white-color);
    .top-paragraph {
        color: var(--additional-color);
    }
`;

const SParagraph = styled(Paragraph)`
    font-size: 1.75rem;
    color: var(--white-color);
    .colored {
        color: var(--additional-color);
    }
`;

const SHeaderSecond = styled(HeaderSecond)`
    color: var(--white-color);
    font-size: clamp(2.875rem, 2.6109rem + 1.1268vw, 3.625rem);
    line-height: 1.2;
    margin: 1.5rem 0 4.5rem;
`;

const SBlockSpan = styled.span`
    display: block;
`;

export const MainContent = () => {
    return (
        <>
            <SHeaderOne topParagraph="careers-section.careers">
                <FormattedMessage id="careers-section.hiWeReMobileReality" />
            </SHeaderOne>
            <SParagraph>
                <FormattedMessage id="careers-section.letsGrow" />
            </SParagraph>
            <SParagraph>
                <FormattedMessage id="careers-section.liveOurLifes" />
            </SParagraph>
            <SParagraph>
                <FormattedMessage id="careers-section.joinUsAndLetsChange" />
            </SParagraph>
            <SHeaderSecond>
                <SBlockSpan>
                    <FormattedMessage id="careers-section.butLetsDoThis" />
                </SBlockSpan>
                <SBlockSpan>
                    <FormattedMessage id="careers-section.letsDoThisOurWay" />
                </SBlockSpan>
            </SHeaderSecond>
        </>
    );
};
