import { PATHS } from 'constants/paths';
import { graphql, useStaticQuery } from 'gatsby';

export const CASE_STUDIES_TAG = {
    WEB_DEVELOPMENT: 'case-studies-page.tags.webDevelopment',
    MOBILE_DEVELOPMENT: 'case-studies-page.tags.mobileDevelopment',
    REACT_NATIVE_DEVELOPMENT: 'case-studies-page.tags.reactNativeDevelopment',
    DATA_ANALYSIS: 'case-studies-page.tags.dataAnalysis',
    DATA_SCIENCE: 'case-studies-page.tags.dataScience',
    CLOUD_APPLICATION_DEVELOPMENT:
        'case-studies-page.tags.cloudApplicationDevelopment',
    MACHINE_LEARNING: 'case-studies-page.tags.machineLearning',
    BUSINESS_INTELLIGENCE: 'case-studies-page.tags.businessIntelligence',
    SMART_CONTRACT_DEVELOPMENT:
        'case-studies-page.tags.smartContractDevelopment',
    NFT_DEVELOPMENT: 'case-studies-page.tags.nftDevelopment',
    AUDIO_STREAMING: 'case-studies-page.tags.audioStreaming',
    VIDEO_STREAMING: 'case-studies-page.tags.videoStreaming',
    LIVE_STREAMING: 'case-studies-page.tags.liveStreaming',
};

export const useCaseStudiesData = () => {
    const images = useStaticQuery(graphql`
        {
            desa: file(
                relativePath: { eq: "case-studies/desa-locum-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            cbre: file(
                relativePath: { eq: "case-studies/cbre-zone-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            nft_reality: file(
                relativePath: { eq: "case-studies/nft-reality-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            musicDistribution: file(
                relativePath: {
                    eq: "case-studies/music-distribution-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            letempick: file(
                relativePath: { eq: "case-studies/letempick-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            tipsters: file(
                relativePath: { eq: "case-studies/4tipsters-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            moneyTransfer: file(
                relativePath: {
                    eq: "case-studies/money-transfer-application-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            airdance: file(
                relativePath: { eq: "case-studies/airdance-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            nomisSolutions: file(
                relativePath: { eq: "case-studies/nomis-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            cryptocurrencyExchangePlatform: file(
                relativePath: {
                    eq: "case-studies/cryptocurrency-exchange-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            salesPredictiveAlgorithm: file(
                relativePath: {
                    eq: "case-studies/sales-predictive-algorithm-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            pregnabit: file(
                relativePath: { eq: "case-studies/pregnabit-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            audioMusicMarketplace: file(
                relativePath: {
                    eq: "case-studies/audio-music-marketplace-miniature.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            churn: file(
                relativePath: { eq: "case-studies/churn-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            urbanOne: file(
                relativePath: { eq: "case-studies/urban-one-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            objekto: file(
                relativePath: { eq: "case-studies/objekto-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            exfluential: file(
                relativePath: { eq: "case-studies/exfluential-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            heyway: file(
                relativePath: { eq: "case-studies/heyway-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            turo: file(
                relativePath: { eq: "case-studies/turo-miniature.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            propertyCostModeler: file(
                relativePath: { eq: "case-studies/property-cost-modeler.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            musicNftMarketplace: file(
                relativePath: { eq: "case-studies/music-nft-marketplace.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            awsCostOptimization: file(
                relativePath: { eq: "case-studies/aws-cost-optimization.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
            meet2more: file(
                relativePath: { eq: "case-studies/meet2more.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 392
                        formats: [WEBP]
                    )
                }
            }
        }
    `);

    return [
        // Proptech
        {
            id: 'objekto',
            title: 'case-studies-page.objekto',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.objekto.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.FACILITY_MANAGEMENT_SOFTWARE}`,
        },
        {
            id: 'turo',
            title: 'case-studies-page.turo',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.turo.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CONSTRUCTION_MANAGEMENT_SOFTWARE_TURO}`,
        },
        {
            id: 'desaLocum',
            title: 'case-studies-page.desaLocum',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.desa.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.REAL_ESTATE_AUCTION_SOFTWARE}`,
        },
        {
            id: 'cbreZone',
            title: 'case-studies-page.cbreZone',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.cbre.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE}`,
        },
        {
            id: 'propertyCostModeler',
            title: 'case-studies-page.propertyCostModeler',
            field: 'case-studies-page.field.realEstate',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.propertyCostModeler.childImageSharp.gatsbyImageData,
            to: null,
        },
        // Fintech
        {
            id: 'moneyTransferApplication',
            title: 'case-studies-page.moneyTransferApplication',
            field: 'case-studies-page.field.fintech',
            tags: [CASE_STUDIES_TAG.MOBILE_DEVELOPMENT],
            image: images.moneyTransfer.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.MONEY_TRANSFER_APPLICATION}`,
        },
        {
            id: 'nomisSolutions',
            title: 'case-studies-page.nomisSolutions',
            field: 'case-studies-page.field.fintech',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.nomisSolutions.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.BANKING_SYSTEM_SOFTWARE}`,
        },
        {
            id: 'cryptocurrencyExchangePlatform',
            title: 'case-studies-page.cryptocurrencyExchangePlatform',
            field: 'case-studies-page.field.fintech',
            tags: [CASE_STUDIES_TAG.WEB_DEVELOPMENT],
            image: images.cryptocurrencyExchangePlatform.childImageSharp
                .gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CRYPTOCURRENCY_EXCHANGE_SOFTWARE}`,
        },
        {
            id: 'churn',
            title: 'case-studies-page.churn',
            field: 'case-studies-page.field.dataAnalysis',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.DATA_ANALYSIS,
                CASE_STUDIES_TAG.DATA_SCIENCE,
                CASE_STUDIES_TAG.BUSINESS_INTELLIGENCE,
            ],
            image: images.churn.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.CUSTOMER_CHURN_PREDICTION_SOFTWARE}`,
        },
        {
            id: '4Tipsters',
            title: 'case-studies-page.4Tipsters',
            field: 'case-studies-page.field.fintech',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.tipsters.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.TIPSTERS}`,
        },
        // Blockchain
        {
            id: 'nftReality',
            title: 'case-studies-page.nftReality',
            field: 'case-studies-page.field.nftBlockchain',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.SMART_CONTRACT_DEVELOPMENT,
                CASE_STUDIES_TAG.NFT_DEVELOPMENT,
            ],
            image: images.nft_reality.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.NFT_REALITY}`,
        },
        {
            id: 'musicNftMarketplace',
            title: 'case-studies-page.musicNftMarketplace',
            field: 'case-studies-page.field.nftBlockchain',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.SMART_CONTRACT_DEVELOPMENT,
                CASE_STUDIES_TAG.NFT_DEVELOPMENT,
            ],
            image: images.musicNftMarketplace.childImageSharp.gatsbyImageData,
            to: null,
        },
        // Media & Entertainment
        {
            id: 'musicDistribution',
            title: 'case-studies-page.musicDistribution',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.AUDIO_STREAMING,
            ],
            image: images.musicDistribution.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.MUSIC_DISTRIBUTION_SOFTWARE}`,
        },
        {
            id: 'audioMusicMarketplace',
            title: 'case-studies-page.audioMusicMarketplace',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.AUDIO_STREAMING,
            ],
            image: images.audioMusicMarketplace.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.AUDIO_MUSIC_MARKETPLACE}`,
        },
        {
            id: 'letempick',
            title: 'case-studies-page.letempick',
            field: 'case-studies-page.field.videoAudioProcessing',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.letempick.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.SURVEY_SOFTWARE}`,
        },
        {
            id: 'airdance',
            title: 'case-studies-page.airdance',
            field: 'case-studies-page.field.streaming',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.VIDEO_STREAMING,
                CASE_STUDIES_TAG.LIVE_STREAMING,
            ],
            image: images.airdance.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.DANCE_LIVE_STREAMING_SOFTWARE}`,
        },
        // Custom software
        {
            id: 'awsCostOptimization',
            title: 'case-studies-page.awsCostOptimization',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.awsCostOptimization.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.AWS_COST_OPTIMIZATION}`,
        },
        {
            id: 'meet2more',
            title: 'case-studies-page.meet2more',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.meet2more.childImageSharp.gatsbyImageData,
            to: null,
        },
        {
            id: 'pregnabit',
            title: 'case-studies-page.pregnabit',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.DATA_SCIENCE,
                CASE_STUDIES_TAG.MACHINE_LEARNING,
            ],
            image: images.pregnabit.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.PREGNABIT}`,
        },
        {
            id: 'salesPredictiveAlgorithm',
            title: 'case-studies-page.salesPredictiveAlgorithm',
            field: 'case-studies-page.field.dataAnalysis',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
                CASE_STUDIES_TAG.DATA_SCIENCE,
                CASE_STUDIES_TAG.MACHINE_LEARNING,
                CASE_STUDIES_TAG.BUSINESS_INTELLIGENCE,
            ],
            image: images.salesPredictiveAlgorithm.childImageSharp
                .gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.SALES_FORECASTING_SOFTWARE}`,
        },

        /* {
            id: 'urbanOne',
            title: 'case-studies-page.urbanOne',
            field: 'case-studies-page.field.realEstate',
            description: 'case-studies-page.webDevelopment',
            image: images.urbanOne.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.URBAN_ONE}`,
        },*/

        {
            id: 'exfluential',
            title: 'case-studies-page.exfluential',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.WEB_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.exfluential.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.EXFLUENTIAL}`,
        },
        {
            id: 'heyway',
            title: 'case-studies-page.heyway',
            field: 'case-studies-page.field.healthcare',
            tags: [
                CASE_STUDIES_TAG.MOBILE_DEVELOPMENT,
                CASE_STUDIES_TAG.CLOUD_APPLICATION_DEVELOPMENT,
            ],
            image: images.heyway.childImageSharp.gatsbyImageData,
            to: `${PATHS.CASE_STUDIES}/${PATHS.HEYWAY}`,
        },
    ];
};
