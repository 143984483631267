import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Arrow } from 'components/arrow';
import { func } from 'prop-types';

const SButtonsWrapper = styled.div`
    display: flex;
    gap: 2rem;
    justify-content: flex-end;

    > button {
        &:first-child {
            transform: rotate(180deg);
        }
    }
`;

const SButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Buttons = ({ scrollPrev, scrollNext }) => {
    const { formatMessage } = useIntl();

    return (
        <SButtonsWrapper>
            <SButton
                aria-label={formatMessage({
                    id: 'main-page.ourCaseStudySection.arrowPrev',
                })}
                type="button"
                aria-controls="carousel-items-button"
                onClick={scrollPrev}
            >
                <Arrow />
            </SButton>
            <SButton
                aria-label={formatMessage({
                    id: 'main-page.ourCaseStudySection.arrowNext',
                })}
                type="button"
                aria-controls="carousel-items-button"
                onClick={scrollNext}
            >
                <Arrow />
            </SButton>
        </SButtonsWrapper>
    );
};

Buttons.propTypes = {
    scrollPrev: func.isRequired,
    scrollNext: func.isRequired,
};
