import React from 'react';
import { motion } from 'framer-motion';
import { array, node, object, oneOfType } from 'prop-types';

const variants = {
    hidden: {
        y: '100%',
        transition: { duration: 0.5 },
    },
    visible: {
        y: 0,
        transition: { duration: 0.5 },
    },
};

export const MotionWrapper = ({ children }) => {
    return <motion.div variants={variants}>{children}</motion.div>;
};

MotionWrapper.propTypes = {
    children: oneOfType([array, object, node]).isRequired,
};
