export const API_URL = {
    MAIL: `${process.env.GATSBY_API_URL}/email/contact`,
    FOOTER_MAIL: `${process.env.GATSBY_API_URL}/email/footer-contact`,
    LANDING_PAGE_MAIL: `${process.env.GATSBY_API_URL}/email/landing-page`,
    ESTIMATE: `${process.env.GATSBY_API_URL}/email/estimate`,
    RECRUITMENT: `${process.env.GATSBY_API_URL}/email/recruitment`,
    MAILBOX: `${process.env.GATSBY_API_URL}/email/experts`,
    GDPR: `${process.env.GATSBY_API_URL}/email/gdpr`,
    NEWSLETTER: `${process.env.GATSBY_API_URL}/email/subscribe-newsletter`,
    START_ESTIMATE_BOT_SESSION: `${process.env.GATSBY_API_URL}/chat-bot/session`,
    ESTIMATE_BOT_MESSAGE: `${process.env.GATSBY_API_URL}/chat-bot/message`,
};

export const GITHUB_REPOS_URL =
    'https://api.github.com/orgs/MobileReality/repos';

const START_DATE = '2021-01-01';
export const generateNpmApiUrl = ({ endDate, packageName }) => {
    return `https://api.npmjs.org/downloads/point/${START_DATE}:${endDate}/@mobile-reality/${packageName}`;
};
