import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { arrayOf, string } from 'prop-types';

const STagsContainer = styled.div`
    display: flex;
    column-gap: 0.5rem;
    items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
`;

const STag = styled.div`
    font-size: 0.688rem;
    color: var(--primary-color);
    margin-bottom: 0.3rem;
    background: var(--primary-color-95);
    width: fit-content;
    padding: 0.3rem 1rem;
    border-radius: 2rem;
    font-weight: 700;
    text-transform: capitalize;
`;

export const CaseStudyTags = ({ tags }) => {
    return (
        <STagsContainer>
            {tags.map((tag) => {
                return (
                    <STag>
                        <FormattedMessage id={tag} />
                    </STag>
                );
            })}
        </STagsContainer>
    );
};

CaseStudyTags.propTypes = {
    tags: arrayOf(string),
};

CaseStudyTags.defaultProps = {
    tags: [],
};
