import React from 'react';
import { css } from 'styled-components';
import { Developers } from 'views/index/home-main-section/components/developers';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { MainSection } from 'modules/main-section';
import { string } from 'prop-types';

export const HomeMainSection = ({ svgTitle }) => {
    return (
        <MainSection
            icon={<Developers title={svgTitle} />}
            iconWrapperCss={css`
                max-width: 43rem;
            `}
            titleId="main-page.mainSection.letsSprint"
            subtitles={['main-page.mainSection.weAreTheTeam']}
            estimateProjectAnalytics={
                gaTrackedEvents.MAIN_PAGE.CTA.ESTIMATE_PROJECT
            }
            introCallAnalytics={gaTrackedEvents.MAIN_PAGE.CTA.INTRO_CALL}
            withSocialMedia
            isMainPage
        />
    );
};

HomeMainSection.prototype = {
    svgTitle: string,
};
