import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useFetchOpenSources } from 'api/open-sources';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { motion, useInView } from 'framer-motion';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import Downloads from 'svgs/icons/ic-downloads.svg';
import Star from 'svgs/icons/ic-star-rounded.svg';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const SCardsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    ${CONSTANTS.MEDIA.max600`
        flex-direction: column;
        align-items: center;
    `}
`;

const SCard = styled.a`
    text-align: center;
    text-decoration: none;
    color: var(--black-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--gray-color);
    padding: 2.75rem 1.625rem 2.25rem;
    transition: ${CONSTANTS.TRANSITION.TRANSITION1};
    max-width: 24.375rem;
    min-height: 16.625rem;
    width: 100%;

    &:hover,
    &:focus-visible {
        box-shadow: 0 0.875rem 2rem rgba(0, 0, 0, 0.1);
    }
`;

const SCardHeading = styled.p`
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -0.75rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 2px;
        background: var(--primary-color);
    }
`;

const SCardDescription = styled.p`
    margin-bottom: 1.4rem;
`;

const SCardTechnology = styled.p`
    font-size: 0.875rem;
    font-weight: bold;
    margin-bottom: 1.25rem;
    margin-top: auto;
`;

const SIconsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
`;

const SIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 2.25rem;
`;

const SWrapper = styled.div`
    overflow: hidden;
`;

const compactFormatNumber = (num) => {
    try {
        return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
    } catch {
        return num;
    }
};

export const OpenSourceSection = () => {
    const data = useFetchOpenSources();
    const ref = useRef(null);

    const isInView = useInView(ref);
    const title = 'main-page.open-source.checkOurGitHubProfile';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id="open-source">
            <SHeaderSecond
                topParagraph="main-page.open-source.github"
                id={kebabCaseId}
                handleCopyUrl={handleCopyUrl}
            >
                <FormattedMessage id="main-page.open-source.checkOurGitHubProfile" />
            </SHeaderSecond>
            <SWrapper ref={ref}>
                <motion.div
                    initial={{
                        x: '-100%',
                    }}
                    animate={{
                        x: isInView ? 0 : '-100%',
                    }}
                    transition={{
                        duration: 0.5,
                        type: 'spring',
                        stiffness: 80,
                    }}
                >
                    <SCardsWrapper>
                        {data.map(
                            ({
                                full_name: fullName,
                                description,
                                language,
                                stargazers_count: stars,
                                totalDownloads,
                                html_url: url,
                                id,
                            }) => (
                                <SCard key={id} href={url} target="_blank">
                                    <SCardHeading>{fullName}</SCardHeading>
                                    <SCardDescription>
                                        {description}
                                    </SCardDescription>
                                    <SCardTechnology>
                                        {language}
                                    </SCardTechnology>
                                    <SIconsWrapper>
                                        <SIconWrapper>
                                            <Star height={24} />
                                            {compactFormatNumber(stars)}
                                        </SIconWrapper>
                                        <SIconWrapper>
                                            <Downloads height={24} />
                                            {compactFormatNumber(
                                                totalDownloads,
                                            )}
                                        </SIconWrapper>
                                    </SIconsWrapper>
                                </SCard>
                            ),
                        )}
                    </SCardsWrapper>
                </motion.div>
            </SWrapper>
        </SContainer>
    );
};
