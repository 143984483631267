import { css, keyframes } from 'styled-components';

const sliding = (tx, ty, tz = 0) => keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(${tx}px, ${ty}px, ${tz}px);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`;
export const slidingAnimation = (tx, ty, tz, duration = '4s') => {
    return css`
        animation: ${sliding(tx, ty, tz)} ${duration} ease-in-out infinite;
    `;
};

const fullSpin = keyframes`
    0% {
      transform:  rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;
export const spinningAnimation = css`
    animation: ${fullSpin} 10s ease-in-out infinite;
    transform-origin: center;
    transform-box: fill-box;
`;

export const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const variant = (variants) => (props) => {
    const variantKey = props.variant;
    return variants[variantKey];
};
