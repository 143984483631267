import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import { array } from 'prop-types';

const SSlide = styled.div`
    flex: 0 0 100%;
    max-width: 100%;
    background: var(--gray-color-90);
    display: flex;
    justify-content: center;
`;

export const CarouselSlides = ({ slides }) => {
    const { formatMessage } = useIntl();

    return slides.map(({ title, image, path }) => {
        const titleTranslated = formatMessage({ id: title });
        return (
            <SSlide key={title} aria-roledescription="slide" role="group">
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                >
                    <PrefixLangLink
                        to={path}
                        aria-label={titleTranslated}
                        title={titleTranslated}
                    >
                        <GatsbyImage alt={titleTranslated} image={image} />
                    </PrefixLangLink>
                </motion.div>
            </SSlide>
        );
    });
};

CarouselSlides.propTypes = {
    slides: array.isRequired,
};
