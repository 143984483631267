import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderFirst } from 'components/header-first';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { SocialMediaBanner } from 'modules/main-section/components/social-media-banner';
import { arrayOf, bool, node, string } from 'prop-types';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const SWrapper = styled.div`
    margin-top: 10.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: ${({ isMainPage }) => (isMainPage ? '3.125rem' : '4.625rem')};
    row-gap: 2.625rem;
    ${CONSTANTS.MEDIA.max1024`
        margin-top: 7rem;
        flex-direction: column;
        text-align: center;
    `}
`;

const STextWrapper = styled.div`
    position: relative;
    flex: 0.5;
    ${({ textWrapperCss }) => textWrapperCss};
`;

const SHeaderFirst = styled(HeaderFirst)`
    margin-bottom: 1.5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 2.625rem;
    font-weight: 400;
    font-size: 1.75rem;
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 0.8rem;
    letter-spacing: 0.2em;
    margin-bottom: 0.8rem;
`;

const SIconWrapper = styled.div`
    flex: 0.55;
    width: 100%;
    ${({ iconWrapperCss }) => iconWrapperCss};
`;

const SParagraph = styled(Paragraph)`
    font-size: 1.375rem;
    margin-bottom: 2.25rem;
`;

export const MainSectionBase = ({
    buttonsSection,
    icon,
    titleId,
    subtitles,
    topSubTitleId,
    withSocialMedia,
    iconWrapperCss,
    isMainPage,
    paragraphs,
    textWrapperCss,
    isParagraphFormattedPreviously,
    isSubtitleFormattedPreviously,
}) => {
    const paragraphsToRender = paragraphs?.map((paragraph) => (
        <SParagraph key={paragraph}>
            {isParagraphFormattedPreviously ? (
                paragraph
            ) : (
                <FormattedMessage id={paragraph} />
            )}
        </SParagraph>
    ));

    const subtitlesToRender = subtitles?.map((subtitle) => (
        <SHeaderSecond key={subtitle}>
            {isSubtitleFormattedPreviously ? (
                subtitle
            ) : (
                <FormattedMessage id={subtitle} />
            )}
        </SHeaderSecond>
    ));

    return (
        <SContainer>
            <SWrapper isMainPage={isMainPage}>
                <STextWrapper textWrapperCss={textWrapperCss}>
                    {withSocialMedia && <SocialMediaBanner />}
                    {topSubTitleId && (
                        <SHeaderFourth>
                            <FormattedMessage id={topSubTitleId} />
                        </SHeaderFourth>
                    )}
                    <SHeaderFirst>
                        <FormattedMessage id={titleId} />
                    </SHeaderFirst>
                    {subtitlesToRender}
                    {paragraphsToRender}
                    {buttonsSection}
                </STextWrapper>
                <SIconWrapper iconWrapperCss={iconWrapperCss}>
                    {icon}
                </SIconWrapper>
            </SWrapper>
        </SContainer>
    );
};

MainSectionBase.propTypes = {
    buttonsSection: node.isRequired,
    icon: node.isRequired,
    titleId: string.isRequired,
    subtitles: arrayOf(string),
    topSubTitleId: string,
    withSocialMedia: bool,
    isMainPage: bool,
    iconWrapperCss: arrayOf(string).isRequired,
    textWrapperCss: arrayOf(string),
    paragraphs: arrayOf(string),
    isDark: bool,
    isParagraphFormattedPreviously: bool,
};

MainSectionBase.defaultProps = {
    withSocialMedia: false,
    isMainPage: false,
    subtitles: [],
    topSubTitleId: '',
    paragraphs: [],
    isDark: false,
    textWrapperCss: [''],
    isParagraphFormattedPreviously: false,
};
