import React from 'react';
import styled from 'styled-components';
import { array, node, object, oneOfType, string } from 'prop-types';

const SSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SectionWrapper = ({ children, className, id }) => {
    const withId = id && { id };
    return (
        <SSectionWrapper {...withId} className={className}>
            {children}
        </SSectionWrapper>
    );
};

SectionWrapper.propTypes = {
    children: oneOfType([array, node, object]).isRequired,
    className: string,
    id: string,
};

SectionWrapper.defaultProps = {
    className: '',
    id: '',
};
