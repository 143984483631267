import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';

const SHeaderSecond = styled(HeaderSecond)`
    color: var(--gray-color);
    transition: color 0.3s ease-in-out;
    &:hover {
        color: var(--black-text-color);
    }
    ${({ isActive }) =>
        isActive &&
        css`
            color: var(--black-text-color);
        `}
    ${CONSTANTS.MEDIA.max1024`
        margin-bottom: 1.25rem;
    `}
`;

export const ServiceTitle = (props) => {
    const { title } = props;
    return (
        <SHeaderSecond {...props}>
            <FormattedMessage id={title} />
        </SHeaderSecond>
    );
};

ServiceTitle.propTypes = {
    title: string.isRequired,
};
